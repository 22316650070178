import { BOOKING_PAYMENT_URL } from 'config/app'

export default async function payBooking(bookingId, query, gateway, method, idToken) {
  return fetch(BOOKING_PAYMENT_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      // 'X-Firebase-AppCheck': appCheckToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: { bookingId, query, gateway, method } }),
  }).then((res) => res.json())
}
